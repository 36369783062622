import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return  <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="How To Stock A Pantry For The First Time"
            description="Here are five easy steps that will take you from an empty pantry to one fully stocked with all the essential cooking and baking ingredients."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                How To Stock A Pantry For The First Time
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/how-to-stock-a-pantry-for-the-first-time.jpg" alt="How To Stock A Pantry For The First Time"/>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            So you just moved into a new home with a brand new, completely empty kitchen. You want to get back into the swing of things, to prepare your favorite meals, try out new recipes, and perhaps revamp your nutrition in some way.
        </Paragraph>
        <Paragraph>
            But how do you go about stocking a pantry for the first time? What are all the basic pantry staples you need? Which ones should you get first? Or can you splurge on an entire new pantry at once?
        </Paragraph>
        <Paragraph>
            All of these are valid questions. We hope to answer all of them in this blog post and help you <InternalLink to = "/pantry-stock-list/">quickly set up a pantry</InternalLink> you can then expand to your heart’s desire.
        </Paragraph>
        <BlogHeading2>
            Step 1: Figure out your budget
        </BlogHeading2>
        <Paragraph>
            Your budget will determine whether you can buy all essential pantry items at once or you need to space out the shopping trips and gradually fill up your pantry.
        </Paragraph>
        <Paragraph>
            Think about <InternalLink to="/how-to-save-money-grocery-shopping/">how much money</InternalLink> you can set aside for the pantry right now. If it isn’t much, then come up with a fixed number you can put away every week (or month) for pantry items until they’re all lining up the pantry shelves.
        </Paragraph>
        <Paragraph>
            For example, maybe you can spare $50 right now, and set aside $20 each week for this very purpose until your pantry is all stocked up. That’s a great plan! With it, all you need is a prioritized list of items to buy each time you visit the grocery store and you can get to work.
        </Paragraph>
        <Paragraph>
            If, however, your budget is big enough to accommodate your entire pantry, you should consider logistics. Can you get all of the items in one shopping trip? Can you go by yourself or will you need more manpower? Can all of it fit into your car? Do you have the time to properly organize it all in your new pantry?
        </Paragraph>
        <Paragraph>
            Even if you can afford the entire pantry stocking in one go, perhaps it would still be better to prioritize your pantry essentials and gradually get all of them over the course of a few weeks. We find that this is way less stressful on the mind.
        </Paragraph>
        <BlogHeading2>
            Step 2: Write a list
        </BlogHeading2>
        <Paragraph>
            Here comes the hard part - figuring out what to get.
        </Paragraph>
        <Paragraph>
            What does a starter kitchen pantry need? Canned goods? Wheat flour? Peanut butter? ...condiments?
        </Paragraph>
        <Paragraph>
            To save you a lot of time and a couple of headaches, we’ve compiled a list of <InternalLink to= "/essential-pantry-items/">essential pantry items to stock up on</InternalLink>. Simply skim through our list, write down the items that fit your palate and cooking style, and voila!
        </Paragraph>
        <Paragraph>
            Bear in mind that our list is by no means a one-list-fits-all kind of deal. This is why we emphasize that you should take into account your own lifestyle and tastes. There is no need to get canned meat if you are vegetarian (obviously), or any kind of chili peppers or chili sauce if you’re not a fan of spicy food. Similarly, you can get alternatives if you prefer them over the items from the same category on our list (such as any kind of nut butter aside from peanut).
        </Paragraph>
        <Paragraph>
            Tailoring the pantry to your household’s needs and meal prep is crucial!
        </Paragraph>
        <BlockQuote>
            A free shopping list app, such as <InternalLink to="/#download-section">Going Shopping</InternalLink>, will do wonders in keeping your list organized and sorted for fast and stress-free shopping.
        </BlockQuote>
        <BlogHeading2>
            Step 3: Prioritize
        </BlogHeading2>
        <Paragraph>
            Before you set off to the nearest grocery shop, you will need to prioritize the ingredient list. What should you get first? What do you need right now, immediately, so that you don’t starve by the time you get to a well stocked pantry?
        </Paragraph>
        <Paragraph>
            Here is our advice when it comes to getting your first pantry items:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Olive oil</UnorderedListItem>
            <UnorderedListItem>Your preferred cooking oil or non-stick cooking oil spray</UnorderedListItem>
            <UnorderedListItem>Salt and black pepper</UnorderedListItem>
            <UnorderedListItem>Flour</UnorderedListItem>
            <UnorderedListItem>Rice (brown rice or white rice, your preference)</UnorderedListItem>
            <UnorderedListItem>Dried pasta (any shape or shapes you like)</UnorderedListItem>
            <UnorderedListItem>Granulated sugar (or brown sugar, if you prefer)</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Consider also:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Your favorite sauces and condiments (ketchup, mustard, etc.)</UnorderedListItem>
            <UnorderedListItem>Produce: onions and potatoes</UnorderedListItem>
            <UnorderedListItem>Tea</UnorderedListItem>
            <UnorderedListItem>Coffee</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            That should be a good start for your pantry. 
        </Paragraph>
        <Paragraph>
            After the first grocery trip, try to get a couple of items from each category on every following outing. A couple of more spice options, perhaps some baking items if you plan on making baked goods sometime soon, maybe canned tomatoes or canned beans for salads or homemade tacos, and similar. Canned fish, such as canned tuna, is also a great option at the beginning, especially if you are focusing on healthy pantry staples.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src = "../assets/images/blogs/post-2022-8-how-to-stock-a-pantry-for-the-first-time-shelves-with-jars-of-ingredients.png" alt = "Two shelves filled with glass jars of various nuts, seeds, crackers, and baking ingredients."/>
        </ImageWithCaption>
        <BlogHeading2>
            Step 4: Organize
        </BlogHeading2>
        <Paragraph>
            It would be best if you could start organizing and setting up your pantry in a logical way (for you, at least) right from the start. That way, you won’t have to stress over being unable to find a certain pantry staple or getting duplicate items or taking everything out to reorganize the whole area again.
        </Paragraph>
        <Paragraph>
            So right off the bat, try to create ‘zones’ in your pantry. For example, tea and coffee can be grouped together. Onions and potatoes can sit next to each other. Rice and pasta could be on the same pantry shelf, and all of the oils could hang out on a different one.
        </Paragraph>
        <Paragraph>
            Make it make sense to you (and anyone else who will have access to the pantry). The goal here is to minimize the amount of time you spend looking for things and checking whether you need to get more of something.
        </Paragraph>
        <Paragraph>
            Which brings us to our final step.
        </Paragraph>
        <BlogHeading2>
            Step 5: Keep your pantry stocked
        </BlogHeading2>
        <Paragraph>
            Having the perfectly stocked pantry means little if you're not going to keep it stocked. A good rule of thumb is to add to your grocery list whenever you notice you’re running low on a pantry item. 
        </Paragraph>
        <Paragraph>
            Down to the last two or three onions? On the list they go. Only half a jar of strawberry jam left? That, too, goes onto the grocery list.
        </Paragraph>
        <Paragraph>
            Anyone who is using up things from the pantry should adopt this habit. It would be ideal if you could have one grocery shopping list for the whole household, so that everyone involved can add to it as needed.
        </Paragraph>
        <Paragraph>
            Check out our article on <InternalLink to= "/how-to-organize-your-grocery-shopping-list/">how to properly organize your shopping list</InternalLink> for every shopping trip.
        </Paragraph>
        <BlogHeading3>
            Bonus: Expand your pantry
        </BlogHeading3>
        <Paragraph>
            Lastly, don't forget that all of this is just a starting point. As you settle into your new home, you will likely want to expand your pantry with new and exciting ingredients. As you should!
        </Paragraph>
        <Paragraph>
            Being a little (or a lot!) adventurous in the kitchen is one of the great joys in life, and you shouldn't hesitate to play around with your pantry food. Introduce spices from foreign places, reinvent baking recipes, or find new comfort foods for when life gets a little rough. Don't be afraid to try out a new delicious meal now and then!
        </Paragraph>
        <Paragraph>
            The beauty of a basic pantry is that you can build on it in any direction you want. 
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            There we have it, five easy steps that will take you from an empty pantry to one fully stocked with all the essential cooking and baking supplies.
        </Paragraph>
        <Paragraph>
            Once again, we remind you to take your time and go easy on yourself. Remember that lists - especially shopping lists - are your friends. Calculate your budget, write a list of all the items you need, prioritize them - and get to shopping!
        </Paragraph>
        <Paragraph>
            The Going Shopping app will help you put together the pantry you always imagined you'd have. <InternalLink to="/#download-section">Download it now!</InternalLink> (It's free!)
        </Paragraph>
    </StyledMainContent>
        </BlogPostWrapper>
}



export default BlogPost;